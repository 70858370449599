<template>
    <v-row dense align="center">
        <v-col cols="12">
            <div class="font-italic" v-html="schema.description" />
        </v-col>
        <v-col cols="12" class="d-flex align-start justify-start">
            <v-avatar v-if="photo" size="48" icon
                ><img :src="photo" :alt="label"
            /></v-avatar>
            <v-avatar v-else color="primary" size="56" icon
                ><v-icon dark large>mdi-{{ icon }}</v-icon></v-avatar
            >
            <div class="ml-4 d-flex flex-column">
                <p class="text-subtitle-1 ma-0">{{ title }}</p>
                <p class="text-subtitle-2 ma-0">{{ subtitle }}</p>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        label: { type: String, default: '' },
        schema: { type: Object, required: true },
        fullSchema: { type: Object, required: true },
    },
    computed: {
        title() {
            return this.schema['x-config'].title
        },
        subtitle() {
            return this.schema['x-config'].subtitle
        },
        photo() {
            return this.schema['x-config'].photo
        },
        icon() {
            const icon = this.schema['x-config'].icon
            return icon || 'account'
        },
    },
}
</script>

<style scoped></style>
